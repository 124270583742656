<template>
  
  <div class="ibox">
    <div class="ibox-title">
      <h2>{{$t('tools.vat.title-validation')}}</h2>
    </div>
    <div class="ibox-content p-md">

      <form role="form" @submit="validateVAT" name="validateVATForm">
        <div class="form-group row">
          <label for="vatCountryCode" class="col-lg-3 col-form-label">{{ $t('tools.vat.form.country') }}</label>
          <div class="col-lg-9">
            
            <v-select :options="listCountries" label="label" v-model="form.country" id="vatCountryCode" :placeholder="$t('tools.vat.form.country_placeholder')" @keypress.enter.native.prevent="">
              <template #search="{attributes, events}">
                <input
                  class="vs__search"
                  :required="!form.country"
                  v-bind="attributes"
                  v-on="events"
                />
              </template>
            </v-select>

          </div>
        </div>
        <div class="form-group row">
          <label for="validateVatNumber" class="col-lg-3 col-form-label">{{ $t('tools.vat.form.vatNumber') }}</label>
          <div class="col-lg-9">
            <input id="validateVatNumber" type="text" :placeholder="$t('tools.vat.form.vatNumber_placeholder')" class="form-control" v-model="form.vatNumber" required>
          </div>
        </div>
        <div class="form-group row">
          <label for="requestType" class="col-lg-3 col-form-label">{{ $t('tools.vat.form.requestType') }}</label>
          <div class="col-lg-9">
            
            <v-select :options="listRequestTypes" label="label" v-model="form.requestType" id="requestType" :placeholder="$t('tools.vat.form.requestType_placeholder')" @keypress.enter.native.prevent="">
              <template #search="{attributes, events}">
                <input
                  class="vs__search"
                  :required="!form.requestType"
                  v-bind="attributes"
                  v-on="events"
                />
              </template>
            </v-select>

          </div>
        </div>

        <button class="btn btn-primary ladda-button" data-style="zoom-in" type="submit">{{ $t('tools.vat.form.button') }}</button>
      </form>

      <div class="row results" v-if="listResults.length > 0">
        <div class="col-md-auto" v-for="(result, index) in listResults" :key="index">
          <div :class="'panel ' + (result.validated ? 'panel-primary' : 'panel-danger')">
            <div class="panel-heading">
                {{ result.validated ? $t('tools.vat.results.valid') : $t('tools.vat.results.invalid') }}
            </div>
            <div class="panel-body">
              <div class="row">
                <div class="col-md-auto">
                  <div class="row m-sm">{{ $t('tools.vat.results.country') }}</div>
                  <div class="row m-sm">{{ $t('tools.vat.results.vatNumber') }}</div>
                  <div class="row m-sm" v-if="result.validated">{{ $t('tools.vat.results.name') }}</div>
                  <div class="row m-sm" v-if="result.validated">{{ $t('tools.vat.results.address') }}</div>
                </div>
                <div class="col">
                  <div class="row m-sm">{{ result.countryCode }}</div>
                  <div class="row m-sm">{{ result.vatNumber }}</div>
                  <div class="row m-sm" v-if="result.validated">{{ result.name }}</div>
                  <div class="row m-sm" v-if="result.validated">{{ result.address }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<style scoped>
  .row.results {
    margin-top: 20px;
  }
</style>


<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Watch } from '@fwk-node-modules/vue-property-decorator';
import * as api from '@fwk-client/utils/api';
import * as Ladda from 'ladda';

@Component({
  components: {}
})
export default class VatValidation extends Vue {

  form:any = {
    country: null,
    vatNumber: null,
    requestType: null
  };

  laddaSubmit:Ladda.LaddaButton|null = null;
  listCountries:any[] = [];

  listResults:any[] = [];

  listRequestTypes:any[] = [
    {
      label: 'RESTWthAxios',
      code: 'REST_AXIOS'
    },
    {
      label: 'RESTWithHttps',
      code: 'REST_HTTPS'
    },
    {
      label: 'fromForm',
      code: 'FORM'
    },
    {
      label: 'fromSOAP',
      code: 'SOAP'
    }
  ];

  created() {
    this.updateListCountries();
  }

  mounted() {
    var submitButton:HTMLButtonElement|null = document.querySelector( 'form[name=validateVATForm] button.ladda-button' );
    this.laddaSubmit = Ladda.create(submitButton!);
  }

  @Watch('$store.state.languages.currentLanguageCode')
  onLanguageChange(to:any, from:any) {
    this.updateListCountries();
  }

  updateListCountries() {
    // We need to get the list of available companies for the current logged in user
    var options:api.ApiVueOptions =  {
      app: this
    }
    api.getAPI('/api/utils/listEUCountries', options).then((response:any) => {
      if(response.countries) {  
        this.listCountries = response.countries;
      }
    });
  }

  validateVAT(evt:Event) {
    // We prevent submit of the page
    evt.preventDefault();

    var input = {
      "vatNumber" : this.form.vatNumber,
      "countryCode" : this.form.country.code,
      "requestType" : this.form.requestType.code
    }

    var options:api.ApiVueOptions =  {
      app: this
    }

    this.laddaSubmit!.start();
    
    api.postAPI('/api/utils/companies/validateVAT', input, options).then((response:any) => {
      this.listResults.unshift(response);
      this.laddaSubmit!.stop();
    });
  }

}
</script>