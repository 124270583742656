<template>
  
  <div class="ibox">
    <div class="ibox-title">
      <h2>{{$t('tools.siren.title-validation')}}</h2>
    </div>
    <div class="ibox-content p-md">

      <form role="form" @submit="generateVAT" name="generateVATForm">
        <div class="form-group row">
          <label for="generateVATSIRENNumber" class="col-lg-3 col-form-label">{{ $t('tools.siren.form.sirenNumber') }}</label>
          <div class="col-lg-9">
            <input id="generateVATSIRENNumber" type="text" :placeholder="$t('tools.siren.form.sirenNumber_placeholder')" class="form-control" v-model="form.sirenNumber" required>
          </div>
        </div>

        <button class="btn btn-primary ladda-button validateSIREN" data-style="zoom-in" type="button" @click="validateSIREN">{{ $t('tools.siren.form.validateSIRENButton') }}</button>
        <button class="btn btn-primary ladda-button generateVAT" data-style="zoom-in" type="submit">{{ $t('tools.siren.form.generateVATButton') }}</button>
      </form>

      <div class="hr-line-dashed"></div>

      <form role="form" @submit="generateSIRET" name="generateSIRETForm">
        <div class="form-group row">
          <label for="generateSIRETEstablishment" class="col-lg-3 col-form-label">{{ $t('tools.siren.form.establishment') }}</label>
          <div class="col-lg-9">
            <input id="generateSIRETEstablishment" type="text" :placeholder="$t('tools.siren.form.establishment_placeholder')" class="form-control" v-model="form.establishment" required>
          </div>
        </div>

        <button class="btn btn-primary ladda-button generateSIRET" data-style="zoom-in" type="submit">{{ $t('tools.siren.form.generateSIRETButton') }}</button>
      </form>

      <div class="row results" v-if="listResults.length > 0">
        <div class="col-md-auto" v-for="(result, index) in listResults" :key="index">
          <div :class="'panel ' + (result.validated ? 'panel-primary' : 'panel-danger')">
            <div class="panel-heading">
                {{ result.validated ? $t('tools.siren.results.valid') : $t('tools.siren.results.invalid') }}
            </div>
            <div class="panel-body">
              <div class="row">
                <div class="col-md-auto">
                  <div class="row m-sm">{{ $t('tools.siren.results.sirenNumber') }}</div>
                  <div class="row m-sm" v-if="result.establishment">{{ $t('tools.siren.results.establishment') }}</div>
                  <div class="row m-sm" v-if="result.siretNumber">{{ $t('tools.siren.results.siretNumber') }}</div>
                  <div class="row m-sm" v-if="result.vatNumber">{{ $t('tools.siren.results.vatNumber') }}</div>
                </div>
                <div class="col">
                  <div class="row m-sm">{{ result.sirenNumber }}</div>
                  <div class="row m-sm" v-if="result.establishment">{{ result.establishment }}</div>
                  <div class="row m-sm" v-if="result.siretNumber">{{ result.siretNumber }}</div>
                  <div class="row m-sm" v-if="result.vatNumber">{{ result.vatNumber }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<style scoped>
  .row.results {
    margin-top: 20px;
  }
</style>


<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Watch } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';
import Messages from '../../panels/Messages.vue';
import * as api from '@fwk-client/utils/api';
import * as Ladda from 'ladda';

@Component({
  components: {}
})
export default class SirenValidation extends Vue {

  form:any = {
    sirenNumber: null,
    establishment: '0001'
  };

  laddaValidateSIRENSubmit:Ladda.LaddaButton|null = null;
  laddaGenerateSIRETSubmit:Ladda.LaddaButton|null = null;
  laddaGenerateVATSubmit:Ladda.LaddaButton|null = null;

  listResults:any[] = [];

  mounted() {
    var validateSIRENButton:HTMLButtonElement|null = document.querySelector( 'form[name=generateVATForm] button.ladda-button.validateSIREN' );
    this.laddaValidateSIRENSubmit = Ladda.create(validateSIRENButton!);

    var generateSIRETButton:HTMLButtonElement|null = document.querySelector( 'form[name=generateSIRETForm] button.ladda-button.generateSIRET' );
    this.laddaGenerateSIRETSubmit = Ladda.create(generateSIRETButton!);

    var generateVATButton:HTMLButtonElement|null = document.querySelector( 'form[name=generateVATForm] button.ladda-button.generateVAT' );
    this.laddaGenerateVATSubmit = Ladda.create(generateVATButton!);
  }

  validateSIREN(evt:Event) {
    // We prevent submit of the page
    evt.preventDefault();

    var input = {
      "sirenNumber" : this.form.sirenNumber
    }

    var options:api.ApiVueOptions =  {
      app: this
    }

    this.laddaValidateSIRENSubmit!.start();
    
    api.postAPI('/api/utils/companies/validateSIREN', input, options).then((response:any) => {
      if(response.validated) {
        this.listResults.unshift(response);
      }
      this.laddaValidateSIRENSubmit!.stop();
    });
  }

  generateSIRET(evt:Event) {
    // We prevent submit of the page
    evt.preventDefault();

    var input = {
      "sirenNumber" : this.form.sirenNumber,
      "establishment" : this.form.establishment
    }

    var options:api.ApiVueOptions =  {
      app: this
    }

    this.laddaGenerateSIRETSubmit!.start();
    
    api.postAPI('/api/utils/companies/generateSIRET', input, options).then((response:any) => {
      if(response.siretNumber) {
        this.listResults.unshift(response);
      }
      this.laddaGenerateSIRETSubmit!.stop();
    });
  }

  generateVAT(evt:Event) {
    // We prevent submit of the page
    evt.preventDefault();

    var input = {
      "sirenNumber" : this.form.sirenNumber
    }

    var options:api.ApiVueOptions =  {
      app: this
    }

    this.laddaGenerateVATSubmit!.start();
    
    api.postAPI('/api/utils/companies/generateVAT', input, options).then((response:any) => {
      if(response.vatNumber) {
        this.listResults.unshift(response);
      }
      this.laddaGenerateVATSubmit!.stop();
    });
  }

}
</script>