<template>
  
  <div class="ibox">
    <div class="ibox-title">
      <h2>Search Company</h2>
    </div>
    <div class="ibox-content p-md">

      <form role="form" @submit="searchCompany" name="searchCompanyForm">
        <div class="form-group row">
          <label for="searchCompanyName" class="col-lg-3 col-form-label">Name</label>
          <div class="col-lg-9">
            <input id="searchCompanyName" type="text" placeholder="Name" class="form-control" v-model="form.companyName" required>
          </div>
        </div>
        <button class="btn btn-primary ladda-button" data-style="zoom-in" type="submit">Search</button>
      </form>

      <div class="row results" v-if="listResults.length > 0">
        <div class="col-md-auto" v-for="(result, index) in listResults" :key="index">
          <div :class="'panel panel-primary'">
            <div class="panel-body">
              <div class="row">
                <div class="col-md-auto">
                  <div class="row m-sm">Name</div>
                  <div class="row m-sm">SIREN</div>
                  <div class="row m-sm">SIRET</div>
                  <div class="row m-sm">Address</div>
                </div>
                <div class="col">
                  <div class="row m-sm">{{ result.companyName }}</div>
                  <div class="row m-sm">{{ result.sirenNumber }}</div>
                  <div class="row m-sm">{{ result.siretNumber }}</div>
                  <div class="row m-sm">
                    <div class="col">
                      <div class="row">{{ result.address.name }}</div>
                      <div class="row" v-for="(line, index) in result.address.addressLines" :key="index">{{ line }}</div>
                      <div class="row">{{result.address.postalCode}} {{result.address.city}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<style scoped>
  .row.results {
    margin-top: 20px;
  }
</style>


<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Watch } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';
import Messages from '../../panels/Messages.vue';
import * as api from '@fwk-client/utils/api';
import * as Ladda from 'ladda';

@Component({
  components: {}
})
export default class CompanySearch extends Vue {

  form:any = {
    companyName: null
  };

  laddaSubmit:Ladda.LaddaButton|null = null;

  listResults:any[] = [];

  mounted() {
    var submitButton:HTMLButtonElement|null = document.querySelector( 'form[name=searchCompanyForm] button.ladda-button' );
    this.laddaSubmit = Ladda.create(submitButton!);
  }

  searchCompany(evt:Event) {
    // We prevent submit of the page
    evt.preventDefault();

    var input = {
      "companyName" : this.form.companyName
    }

    var options:api.ApiVueOptions =  {
      app: this
    }

    this.laddaSubmit!.start();
    this.listResults = [];
    
    api.postAPI('/api/utils/companies/searchCompany', input, options).then((response:any) => {
      if(response.companies) {
        this.listResults = response.companies;
      }
      this.laddaSubmit!.stop();
    });
  }

}
</script>